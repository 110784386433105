<script>
/* eslint-disable */
import {ref} from 'vue';
import useVuelidate from '@vuelidate/core';

import DropZone from '@/components/widgets/dropZone';
import datepicker from 'vue3-datepicker';

import PageHeader from '@/components/page-header';
import appConfig from '@/app.config';
import Layout from '../../layouts/main';

/**
 * Projects-create component
 */
export default {
  page: {
    title: 'Create New Project',
    meta: [{name: 'description', content: appConfig.description}],
  },
  setup() {
    const dropzoneFile = ref('');
    const drop = (e) => {
      dropzoneFile.value = e.dataTransfer.files[0];
    };
    const selectedFile = () => {
      dropzoneFile.value = document.querySelector('.dropzoneFile').files[0];
    };
    return {
      dropzoneFile, drop, selectedFile, v$: useVuelidate(),
    };
  },
  components: {
    DropZone,
    datepicker,
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: 'Create New',
      items: [
        {
          text: 'Projects',
          href: '/',
        },
        {
          text: 'Create New',
          active: true,
        },
      ],
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: {'My-Awesome-Header': 'header value'},
      },
      daterange: ref(new Date()),
    };
  },
};
</script>

<template>
  <Layout>
    <PageHeader :items="items" :title="title"/>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">Create New Project</h4>
            <form>
              <div class="form-group row mb-4">
                <label class="col-form-label col-lg-2" for="projectname"
                >Project Name</label
                >
                <div class="col-lg-10">
                  <input
                    id="projectname"
                    class="form-control"
                    name="projectname"
                    placeholder="Enter Project Name..."
                    type="text"
                  />
                </div>
              </div>
              <div class="form-group row mb-4">
                <label class="col-form-label col-lg-2" for="projectdesc"
                >Project Description</label
                >
                <div class="col-lg-10">
                  <textarea
                    id="projectdesc"
                    class="form-control"
                    placeholder="Enter Project Description..."
                    rows="3"
                  ></textarea>
                </div>
              </div>

              <div class="form-group row mb-4">
                <label class="col-form-label col-lg-2">Project Date</label>
                <div class="col-lg-10">
                  <datepicker
                    v-model="daterange"
                    append-to-body
                    class="form-control"
                    confirm
                    lang="en"
                    range
                  ></datepicker>
                </div>
              </div>

              <div class="form-group row mb-4">
                <label class="col-form-label col-lg-2" for="projectbudget"
                >Budget</label
                >
                <div class="col-lg-10">
                  <input
                    id="projectbudget"
                    class="form-control"
                    name="projectbudget"
                    placeholder="Enter Project Budget..."
                    type="text"
                  />
                </div>
              </div>
            </form>
            <div class="row mb-4">
              <label class="col-form-label col-lg-2">Attached Files</label>
              <div class="col-lg-10">
                <DropZone @change="selectedFile" @drop.prevent="drop"/>
                <span class="file-info">File: {{ dropzoneFile.name }}</span>
              </div>
            </div>
            <div class="row justify-content-end">
              <div class="col-lg-10">
                <button class="btn btn-primary" type="submit">
                  Create Project
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>
